import React, { createContext, useState, useEffect } from 'react'
import { geocodingClient } from '../Map'

// https://developer.mozilla.org/en-US/docs/Web/API/Geolocation/getCurrentPosition
const getCurrentPosition = () =>
  new Promise((resolve, reject) => {
    window.navigator.geolocation.getCurrentPosition(resolve, reject, {
      enableHighAccuracy: true,
      maximumAge: Infinity,
      timeout: 5000
    })
  })

// https://github.com/mapbox/mapbox-sdk-js/blob/2e8d63e3e2a6460b86cfcb9d470b90fbfc2bf39e/docs/services.md#reversegeocode
const requestAddressByPosition = (longitude, latitude) =>
  geocodingClient
    .reverseGeocode({
      query: [longitude, latitude],
      countries: ['BR'],
      types: ['address'],
      language: ['pt'],
      limit: 1
    })
    .send()

// https://github.com/mapbox/mapbox-sdk-js/blob/2e8d63e3e2a6460b86cfcb9d470b90fbfc2bf39e/docs/services.md#forwardgeocode
const requestAddressByQuery = (query, longitude, latitude) =>
  geocodingClient
    .forwardGeocode({
      query,
      countries: ['BR'],
      proximity: [longitude, latitude],
      types: ['address'],
      language: ['pt'],
      limit: 5
    })
    .send()

const requestAddress = ({ text, longitude, latitude }) =>
  text
    ? requestAddressByQuery(text, longitude, latitude)
    : requestAddressByPosition(longitude, latitude)

export const Location = createContext(null)

export const LocationProvider = ({ children }) => {
  const [query, setQuery] = useState({
    text: '',
    longitude: null,
    latitude: null
  })

  const [results, setResults] = useState(null)

  const [busy, setBusy] = useState(false)

  const requestCurrentPosition = (text = '') => {
    setBusy(true)

    getCurrentPosition()
      .then(currentPosition => {
        const { longitude, latitude } = currentPosition.coords

        setQuery({ text, longitude, latitude })
      })
      .finally(() => setBusy(false))
  }

  useEffect(() => {
    if (!query.latitude || !query.longitude) return

    setBusy(true)

    requestAddress(query)
      .then(response => {
        const match = response.body

        setResults(match.features)
      })
      .finally(() => setBusy(false))
  }, [query.text, query.latitude, query.longitude])

  return (
    <Location.Provider
      value={{
        busy,
        results,
        query,
        requestCurrentPosition
      }}
    >
      {children}
    </Location.Provider>
  )
}
