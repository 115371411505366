import React from 'react'
import { Field, ErrorMessage } from 'formik'

export const CategoryField = () => {
  return (
    <div className="form-group">
      <label htmlFor="marcacaoCategoria" className="col-sm-2 control-label">
        Categoria
      </label>
      <div className="col-sm-10">
        <Field
          component="select"
          id="marcacaoCategoria"
          className="form-control"
          name="categoria"
          data-required="true"
          data-show-errors="false"
        >
          <option />
          <option value="alagamento">Alagamento</option>
          <option value="buraco">Buraco</option>
          <option value="vazamento">Vazamento</option>
          <option value="iluminacao">Iluminação</option>
          <option value="lixo">Lixo</option>
          <option value="manutencao">Manutenção</option>
          <option value="seguranca">Segurança</option>
          <option value="transito">Trânsito</option>
          <option value="outros">Outros</option>
        </Field>
        <ErrorMessage name="categoria" className="help-block" />
      </div>
    </div>
  )
}
