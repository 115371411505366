import React from 'react'
import { Formik, Form } from 'formik'
import { validationSchema } from './validationSchema'

export const EventDetailForm = ({ initialValues = {}, onSubmit, children }) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {() => <Form className="form-horizontal">{children}</Form>}
    </Formik>
  )
}
