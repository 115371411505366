import React, { useState, useEffect } from 'react'
import {
  findImages,
  addImage,
  removeImage
} from '../../resources/eventResource'
import { Loading } from '../../components/Loading'

const imageToDataUri = img => {
  // create an off-screen canvas
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  // set its dimension to target size
  canvas.width = 500
  canvas.height = img.height * (canvas.width / img.width)

  // draw source image into the off-screen canvas:
  ctx.drawImage(img, 0, 0, canvas.width, canvas.height)

  // encode image to data-uri with base64 version of compressed image
  return canvas.toDataURL('image/png', 0.5)
}

export const EventEditImages = ({ event }) => {
  const [loading, setLoading] = useState(true)
  const [eventImages, setEventImages] = useState({})

  useEffect(() => {
    findImages(event._id).then(result => {
      setEventImages(result)
      setLoading(false)
    })
  }, [event._id])

  if (loading) {
    return <Loading />
  }

  return (
    <div className="container">
      <div className="panel panel-default">
        <div className="panel-heading">
          <h4>Fotos</h4>
        </div>
        <div className="panel-body">
          <input
            type="file"
            multiple
            onChange={evt => {
              evt.preventDefault()

              const reader = new FileReader()

              reader.readAsDataURL(evt.target.files[0])

              reader.onload = evt => {
                const img = new Image()
                img.src = evt.target.result

                img.onload = () => {
                  const dataUri = imageToDataUri(img)

                  addImage(event._id, dataUri)
                }

                reader.onerror = error => console.log(error)
              }
            }}
          />
        </div>
        <div className="panel-footer">
          <div className="row">
            {eventImages.imagens.map(image => (
              <div key={image.id} className="col-sm-6 col-md-4">
                <div className="thumbnail">
                  <div className="text-center">
                    <p>
                      <img alt="uploaded" src={image.dataUri} />
                    </p>
                  </div>
                  <div className="caption">
                    <button
                      type="button"
                      className="btn btn-danger btn-xs"
                      onClick={evt => {
                        evt.preventDefault()

                        removeImage(event._id, image.id)
                      }}
                    >
                      <i className="fa fa-trash-o" /> Remover
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
