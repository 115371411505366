import {
  Stitch,
  RemoteMongoClient,
  AnonymousCredential,
  BSON
} from 'mongodb-stitch-browser-sdk'

export const ObjectId = id => new BSON.ObjectID(id)

export const client = Stitch.initializeDefaultAppClient('dedoduro-ofibk')

client.auth.loginWithCredential(new AnonymousCredential())

export const db = client
  .getServiceClient(RemoteMongoClient.factory, 'mongodb-atlas')
  .db('dedoduro')
