import React, { useEffect, useState } from 'react'
import { Link } from '@reach/router'
import { Loading } from '../../components/Loading'
import { findRelated } from '../../resources/eventResource'
import moment from 'moment'

const selectCategoriaName = event => {
  if (event.categoria === 'alagamento') {
    return 'Alagamento'
  }

  if (event.categoria === 'buraco') {
    return 'Buraco'
  }

  if (event.categoria === 'iluminacao') {
    return 'Iluminação'
  }

  if (event.categoria === 'lixo') {
    return 'Lixo'
  }

  if (event.categoria === 'manutencao') {
    return 'Manutenção'
  }

  if (event.categoria === 'seguranca') {
    return 'Segurança'
  }

  if (event.categoria === 'transito') {
    return 'Trânsito'
  }

  if (event.categoria === 'vazamento') {
    return 'Vazamentos'
  }

  return 'Outros'
}

export const EventRelated = ({ event }) => {
  const [loading, setLoading] = useState(true)
  const [events, setEvents] = useState({})

  useEffect(() => {
    findRelated(event._id).then(results => {
      setEvents(results)
      setLoading(false)
    })
  }, [event])

  if (loading) {
    return <Loading />
  }

  return (
    <div className="row">
      <div className="panel panel-default">
        <div className="panel-heading">
          <h4 className="panel-title">Veja também</h4>
        </div>
        <div className="list-group">
          {events.map(marcador => (
            <Link to={`/events/${marcador._id}`} className="list-group-item">
              {selectCategoriaName(marcador.categoria)} na{' '}
              {marcador.endereco.nome}, {marcador.endereco.numero},{' '}
              {marcador.endereco.bairro}, {marcador.endereco.cidade} dedurado em{' '}
              {moment(marcador.created_at).format('DD/MM/YYYY')}
            </Link>
          ))}
        </div>
      </div>
    </div>
  )
}
