import React from 'react'
import { Link } from '@reach/router'
import { create } from '../../resources/eventResource'

import {
  EventDetailForm,
  AddressField,
  AddressNumberField,
  AddressCityField,
  CategoryField,
  DescriptionField
} from '../EventDetailForm'

export const EventCreateDetails = ({ location }) => {
  const { state = {} } = location
  const { name = '', coords, context = {} } = state

  return (
    <EventDetailForm
      initialValues={{
        address: name,
        city: context.place,
        categoria: '',
        descricao: ''
      }}
      onSubmit={values => {
        create({
          endereco: {
            nome: name,
            numero: values.enderecoNumero,
            bairro: context.neighborhood,
            cidade: context.place,
            estado: context.region,
            cep: context.postcode,
            pais: context.country
          },
          categoria: values.categoria,
          descricao: values.descricao,
          coords
        })
      }}
    >
      <div className="bs-header">
        <h1>Dedurar</h1>
        <p>Preencha corretamente as informações</p>
      </div>
      <div className="container">
        <AddressField
          helpBlock={
            <Link to="/new">Endereço incorreto? Buscar novamente.</Link>
          }
        />
        <AddressNumberField />
        <AddressCityField />
        <CategoryField />
        <DescriptionField />
        <div className="row">
          <div className="col-sm-offset-2 col-sm-8">
            <div className="well well-sm">
              Suas <strong>fotos</strong> e <strong>videos</strong> ficam para a
              próxima etapa =)
            </div>
          </div>
          <div className="col-sm-1">
            <button type="submit" className="btn btn-warning">
              <i className="fa fa-hand-o-up" />
              Dedurar
            </button>
          </div>
        </div>
      </div>
    </EventDetailForm>
  )
}
