import React from 'react'
import { Link } from '@reach/router'

export const Header = () => (
  <header
    id="navbar"
    className="navbar navbar-default navbar-fixed-top"
    role="banner"
  >
    <div className="navbar-header">
      <button
        className="navbar-toggle collapsed"
        type="button"
        data-toggle="collapse"
        data-target=".bs-navbar-collapse"
      >
        <i className="fa fa-bars fa-lg" />
      </button>
      <Link to="/" className="navbar-brand">
        <span style={{ color: 'rgb(255, 179, 14)' }}>DEDO</span>
        <span style={{ color: '#6E7275' }}>DURO</span>
      </Link>
    </div>
    <nav
      className="navbar-collapse bs-navbar-collapse collapse"
      role="navigation"
    >
      <ul className="nav navbar-nav">
        <li>
          <Link to="new">Dedurar</Link>
        </li>
        <li>
          <Link to="events/map">Mapa</Link>
        </li>
        <li>
          <Link to="events">Listagem</Link>
        </li>
        <li>
          <Link to="mine">Minhas Deduradas</Link>
        </li>
        <li>
          <Link to="settings">Configurações</Link>
        </li>
        <li>
          <Link to="about">Sobre</Link>
        </li>
        {window.currentUser ? (
          <li>
            <button className="logout">Sair</button>
          </li>
        ) : (
          <li>
            <Link to="login">Login</Link>
          </li>
        )}
      </ul>
    </nav>
  </header>
)
