import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'

import './jquery'
import 'Parsley.js/dist/parsley.min.js'
import 'bootstrap/dist/js/bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/css/bootstrap-theme.min.css'
import 'font-awesome/css/font-awesome.min.css'

import './stylesheets/main.css'
import './stylesheets/cidadao.css'
import './stylesheets/dedurada.css'
import './stylesheets/dedurar.css'
import './stylesheets/intro.css'
import './stylesheets/listagem.css'
import './stylesheets/login.css'
import './stylesheets/mapa.css'
import './stylesheets/navbar.css'

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
