import React from 'react'
import { Field } from 'formik'

export const AddressField = ({ helpBlock }) => {
  return (
    <div className="form-group">
      <label className="col-sm-2 control-label">Endereço</label>
      <div className="col-sm-10">
        <Field name="address" className="form-control" tabIndex="-1" readOnly />
        {helpBlock && <p className="help-block">{helpBlock}</p>}
      </div>
    </div>
  )
}
