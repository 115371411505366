import React, { useState, useEffect } from 'react'
import { Router, Link } from '@reach/router'
import { findDetail } from '../../resources/eventResource'
import { EventEditImages } from '../EventEditImages'
// import { EventEditVideos } from '../EventEditVideos'
import { EventEditDetail } from '../EventEditDetail'
import { Loading } from '../../components/Loading'

export const EventEdit = ({ eventId }) => {
  const [loading, setLoading] = useState(true)
  const [event, setEvent] = useState({})

  useEffect(() => {
    findDetail(eventId).then(result => {
      setEvent(result)
      setLoading(false)
    })
  }, [eventId])

  if (loading) {
    return <Loading />
  }

  return (
    <div>
      <div className="bs-header">
        <h1>Administrar Dedurada</h1>
        <p>Acompanhe e Detalhe sua Dedurada</p>
        <div className="btn-group">
          <Link to=".." className="btn btn-default">
            <i className="fa fa-share-square-o" />
            Visualizar
          </Link>
          <Link to={`/events/map/${eventId}`} className="btn btn-default">
            <i className="fa fa-map-marker" />
            Mapa
          </Link>
        </div>
      </div>

      <div className="container">
        <ul className="nav nav-pills nav-justified nav-inline">
          <li>
            <Link to=".">
              <span className="fa-stack fa-lg">
                <i className="fa fa-circle fa-stack-2x" />
                <i className="fa fa-pencil fa-stack-1x fa-inverse" />
              </span>
              <h4 className="hidden-xs">Informações</h4>
            </Link>
          </li>
          <li>
            <Link to="images">
              <span className="fa-stack fa-lg">
                <i className="fa fa-circle fa-stack-2x" />
                <i className="fa fa-camera-retro fa-stack-1x fa-inverse" />
              </span>
              <h4 className="hidden-xs">Fotos</h4>
            </Link>
          </li>
          {/*
          <li>
            <Link to="videos">
              <span className="fa-stack fa-lg">
                <i className="fa fa-circle fa-stack-2x" />
                <i className="fa fa-youtube-play fa-stack-1x fa-inverse" />
              </span>
              <h4 className="hidden-xs">Videos</h4>
            </Link>
          </li>
          */}
        </ul>
      </div>

      <br />

      <Router>
        <EventEditDetail event={event} default />
        <EventEditImages event={event} path="/images" />
        {/* <EventEditVideos event={event} path="/videos" /> */}
      </Router>
    </div>
  )
}
