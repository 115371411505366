import React, { Fragment } from 'react'
import { Link } from '@reach/router'
import dedoDuro from './dedoDuro.png'

export const Home = () => (
  <Fragment>
    <div className="intro" style={{ backgroundImage: `url(${dedoDuro})` }} />
    <div className="intro-slogan">
      <div className="container">
        <h4>
          Um canal de comunicação com os responsáveis, colaboração de pessoas
          que convivem com o mesmo problema e acompanhamento do problema.
        </h4>
      </div>
    </div>
    <div className="container">
      <br />
      <div className="row">
        <div className="intro-crie-marcador col-sm-6 col-sm-offset-3">
          <h3>Crie uma causa social</h3>
          <h4>Juntos vamos conquistar um ambiente melhor para viver!</h4>
          <Link to="new" className="btn btn-warning btn-lg">
            Dedurar
          </Link>
        </div>
        <p className="col-sm-5 col-sm-offset-1">
          Ao reportar uma ocorrência pelo DedoDuro, as pessoas que convivem na
          sua região e enfrentam o mesmo problema poderão compartilhá-lo,
          aumentando sua visibilidade nas redes sociais.
        </p>
        <p className="col-sm-5">
          Conforme a popularidade da sua ocorrência aumenta, as diversas pessoas
          que compartilharam o problema também farão parte da solicitação, não
          só nas redes sociais mas também na prefeitura e/ou órgãos
          responsáveis.
        </p>
      </div>
      <hr />
      <div className="row">
        <div className="col-sm-4">
          <div className="intro-frase clearfix">
            <div className="col-3">
              <i className="fa fa-frown-o" />
            </div>
            <div className="desc col-9">
              <h4>Problemas rotineiros</h4>
              <p>
                Andando pela rua, preste atenção em problemas rotineiros da
                cidade como: carros estacionados em local proibido, aquelas
                calçadas irregulares, ...
              </p>
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="intro-frase clearfix">
            <div className="col-3">
              <i className="fa fa-hand-o-up" />
            </div>
            <div className="desc col-9">
              <h4>Dedure</h4>
              <p>Tire fotos, pense em um titulo e descreva o que acontece...</p>
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="intro-frase clearfix">
            <div className="col-3">
              <i className="fa fa-users" />
            </div>
            <div className="desc col-9">
              <h4>Juntos podemos mais</h4>
              <p>
                Pronto, a Dedurada foi concluida e encaminhada aos responsáveis.
                Lembre-se de divulgar e convidar seus amigos para participar e
                avaliar sua causa. Lembre-se que todos juntos podemos mais.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Fragment>
)
