import React, { useState, useEffect } from 'react'
import { Link } from '@reach/router'
import moment from 'moment'
import { DedoDuroSymbol } from '../../../components/DedoDuroSymbol'
import { Loading } from '../../../components/Loading'
import { find } from '../../../resources/eventResource'

const selectCarouselIndicators = event => {
  const indicators = []

  let totalIndicator = 0

  if (event.imagens && event.imagens.length > 0) {
    totalIndicator += event.imagens.length
  }

  if (event.videos && event.videos.length > 0) {
    totalIndicator += event.videos.length
  }

  for (let i = 0; i < totalIndicator; i++) {
    indicators.push(i)
  }

  return indicators
}

const selectCategoriaName = event => {
  if (event.categoria === 'alagamento') {
    return 'Alagamento'
  }

  if (event.categoria === 'buraco') {
    return 'Buraco'
  }

  if (event.categoria === 'iluminacao') {
    return 'Iluminação'
  }

  if (event.categoria === 'lixo') {
    return 'Lixo'
  }

  if (event.categoria === 'manutencao') {
    return 'Manutenção'
  }

  if (event.categoria === 'seguranca') {
    return 'Segurança'
  }

  if (event.categoria === 'transito') {
    return 'Trânsito'
  }

  if (event.categoria === 'vazamento') {
    return 'Vazamentos'
  }

  return 'Outros'
}

const selectRuaNumero = event => {
  const endereco = event.endereco
  const completo = []

  if (endereco.nome) completo.push(endereco.nome)
  if (endereco.numero) completo.push(endereco.numero)

  return completo.join(', ')
}

const selectBairroCidade = event => {
  const endereco = event.endereco
  const completo = []

  if (endereco.bairro) completo.push(endereco.bairro)
  if (endereco.cidade) completo.push(endereco.cidade)

  return completo.join(', ')
}

export const Event = ({ id }) => {
  const [loading, setLoading] = useState(true)
  const [event, setEvent] = useState([])

  useEffect(() => {
    find(id).then(event => {
      setEvent(event)
      setLoading(false)
    })
  }, [id])

  if (loading) {
    return <Loading />
  }

  const { _id, categoria, created_at: createdAt, imagens = [] } = event

  const carouselIndicators = selectCarouselIndicators(event)
  const ruaNumero = selectRuaNumero(event)
  const bairroCidade = selectBairroCidade(event)
  const categoriaName = selectCategoriaName(event)

  return (
    <div className="marcador col-sm-6 col-lg-4">
      <div className="panel panel-default">
        <div className="panel-heading">
          <div className="media">
            <Link className="pull-left" to={`/events/${_id}`}>
              <DedoDuroSymbol symbol={categoria} />
            </Link>
            <Link className="link" to={`/events/${_id}`}>
              <div className="media-body">
                <h4 className="media-heading">
                  {categoriaName}, {moment(createdAt).format('DD/MM/YYYY')}
                </h4>
                <span>{ruaNumero}</span>
                <span>{bairroCidade}.</span>
              </div>
            </Link>
          </div>
        </div>
        <div id={`c${_id}`} className="carousel slide" data-ride="carousel">
          <ol className="carousel-indicators">
            {carouselIndicators.map((carouselIndicator, i) => (
              <li
                className={i === 0 ? 'active' : ''}
                key={carouselIndicator}
                data-target={`c${_id}`}
                data-slide-to={carouselIndicator}
              />
            ))}
          </ol>
          <div className="carousel-inner">
            {imagens.map((image, i) => (
              <div key={image.id} className={`item ${i === 0 ? 'active' : ''}`}>
                <Link to={`/events/${_id}`}>
                  <div className="text-center">
                    <img alt="event cover" src={image.dataUri} />
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
        <div className="panel-footer" />
      </div>
    </div>
  )
}
