import React from 'react'

import {
  EventDetailForm,
  DescriptionField,
  CategoryField,
  AddressCityField,
  AddressNumberField,
  AddressField
} from '../EventDetailForm'

export const EventEditDetail = ({ event }) => {
  const initialValues = {
    address: event.endereco.nome,
    enderecoNumero: event.endereco.numero,
    city: event.endereco.cidade,
    categoria: event.categoria,
    descricao: event.descricao
  }

  return (
    <EventDetailForm initialValues={initialValues}>
      <div className="container">
        <div className="panel panel-default">
          <div className="panel-heading">
            <h4>Informações</h4>
          </div>
          <div className="panel-body">
            <AddressField />
            <AddressNumberField />
            <AddressCityField />
            <CategoryField />
            <DescriptionField />
          </div>
          <div className="panel-footer text-right">
            <button id="marcadorEdit" type="submit" className="btn btn-success">
              <i className="fa fa-check" />
              Salvar
            </button>
          </div>
        </div>
      </div>
    </EventDetailForm>
  )
}
