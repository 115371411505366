import React, { Fragment, useEffect, useState } from 'react'
import { Link } from '@reach/router'
import { Loading } from '../../components/Loading'
import { find } from '../../resources/eventResource'
import { Header } from './components/Header'
import { EventNear } from '../EventNear'
import { EventRelated } from '../EventRelated/EventRelated'
import moment from 'moment'
import { Carousel } from './components/Carousel'

const selectCategoriaName = event => {
  if (event.categoria === 'alagamento') {
    return 'Alagamento'
  }

  if (event.categoria === 'buraco') {
    return 'Buraco'
  }

  if (event.categoria === 'iluminacao') {
    return 'Iluminação'
  }

  if (event.categoria === 'lixo') {
    return 'Lixo'
  }

  if (event.categoria === 'manutencao') {
    return 'Manutenção'
  }

  if (event.categoria === 'seguranca') {
    return 'Segurança'
  }

  if (event.categoria === 'transito') {
    return 'Trânsito'
  }

  if (event.categoria === 'vazamento') {
    return 'Vazamentos'
  }

  return 'Outros'
}

const selectEnderecoCompleto = event => {
  const endereco = event.endereco
  const completo = []

  if (endereco.nome) completo.push(endereco.nome)
  if (endereco.numero) completo.push(endereco.numero)
  if (endereco.bairro) completo.push(endereco.bairro)
  if (endereco.cidade) completo.push(endereco.cidade)

  return completo.join(', ')
}

export const EventDetail = ({ eventId }) => {
  const [loading, setLoading] = useState(true)
  const [event, setEvent] = useState({})

  useEffect(() => {
    find(eventId).then(result => {
      setEvent(result)
      setLoading(false)
    })
  }, [eventId])

  if (loading) {
    return <Loading />
  }

  let isAutor, currentUser

  return (
    <Fragment>
      <Header event={event} />

      <div className="intro-slogan">
        <div className="container">
          <div className="row">
            <h4 className="col-sm-10">
              <em>
                {selectCategoriaName(event)} na {selectEnderecoCompleto(event)}{' '}
                dedurado em {moment(event.created_at).format('DD/MM/YYYY')}
              </em>
            </h4>
            <h4 className="col-sm-2">
              <div
                className="fb-share-button"
                data-href="{{uri}}"
                data-type="button"
              />
            </h4>
          </div>
        </div>
      </div>

      <br />

      <div className="container">
        <div className="row">
          <div className="col-sm-8">
            <div className="well well-sm">
              <Carousel imagens={event.imagens} />
            </div>

            <div className="well well-sm">
              <p id="ddDescricao">{event.descricao}</p>
            </div>
          </div>
          <div className="col-sm-4">
            <div id="thumbs" className="hide">
              <div className="row">
                <div className="col-xs-12">
                  <h4>Nível de Apoio</h4>
                  {isAutor && (
                    <small>
                      Compartilhe sua dedurada para elevar o nível de apoio.
                    </small>
                  )}
                  <div className="progress progress-striped">
                    <div
                      id="progressBar"
                      className="progress-bar progress-bar-success"
                      style={{ width: event.avgThumbs }}
                    />
                  </div>
                </div>
              </div>
              {currentUser && (
                <Fragment>
                  <div className="row">
                    {isAutor ? (
                      <Fragment>
                        <div id="isAtiva" className="col-xs-12 hide">
                          <div className="alert alert-danger">
                            <strong>Atenção</strong>
                            Sua dedurada não está listada pois não possui{' '}
                            <Link to="edit/images">Fotos</Link> ou{' '}
                            <Link to="edit/videos">Videos</Link>.
                          </div>
                        </div>
                        <div className="col-xs-12">
                          <h4>Gerenciamento</h4>
                          <div className="btn-group btn-group-sm btn-group-justified">
                            <Link className="btn btn-default" to="edit">
                              <i className="fa fa-pencil fa-fw" />
                              <strong className="hidden-sm">Informações</strong>
                            </Link>
                            <Link className="btn btn-default" to="edit/images">
                              <i className="fa fa-camera-retro fa-fw" />
                              <strong className="hidden-sm">Fotos</strong>
                            </Link>
                            <Link className="btn btn-default" to="edit/videos">
                              <i className="fa fa-youtube-play fa-fw" />
                              <strong className="hidden-sm">Vídeos</strong>
                            </Link>
                          </div>
                        </div>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <div className="col-xs-6">
                          <div className="btn-group btn-group-sm">
                            <button id="thumbUp" className="btn btn-default">
                              <i className="fa fa-thumbs-up" />
                              <span>Eu Apoio</span>
                            </button>
                            <button id="thumbDown" className="btn btn-default">
                              <i className="fa fa-thumbs-down" />
                            </button>
                          </div>
                        </div>
                        <div className="col-xs-6 text-right">
                          <Link to="report" className="btn btn-default btn-sm">
                            <i className="fa fa-flag" />
                            Denunciar
                          </Link>
                        </div>
                      </Fragment>
                    )}
                  </div>
                  <br />
                </Fragment>
              )}
            </div>
            <EventNear event={event} />
            <EventRelated event={event} />
          </div>
        </div>
      </div>
    </Fragment>
  )
}
