import React from 'react'

import dedoQuebrado from './dedoQuebrado.png'

export const NotFound = () => {
  return (
    <div className="text-center">
      <h2>Desculpe, esta página não está disponível</h2>

      <h3>
        O link que você seguiu pode estar quebrado, ou a página pode ter sido
        removida.
      </h3>

      <img src={dedoQuebrado} alt="" width="282" height="250" />
    </div>
  )
}
