import { db, ObjectId } from './db'

const collection = () => db.collection('events')

export const create = event => {
  var endereco = {
    nome: event.endereco.nome,
    bairro: event.endereco.bairro,
    cidade: event.endereco.cidade,
    estado: event.endereco.estado,
    cep: event.endereco.cep,
    pais: event.endereco.pais
  }

  if (event.endereco.numero) {
    endereco.numero = event.endereco.numero
  }

  var marcacao = {
    autor: '',
    status: 'novo',
    historico: [{ status: 'novo', when: new Date() }],
    avg: { total: 0, up: 0 },
    aprovacoes: [],
    denuncias: [],
    descricao: event.descricao,
    categoria: event.categoria,
    endereco,
    coordenadas: {
      lat: event.coords.latitude,
      lng: event.coords.longitude
    },
    created_at: new Date(),
    updated_at: new Date()
  }

  return collection().insertOne(marcacao)
}

export const find = id => {
  return collection().findOne(
    { _id: ObjectId(id) },
    {
      projection: {
        _id: 1,
        categoria: 1,
        created_at: 1,
        descricao: 1,
        imagens: 1,
        videos: 1,
        endereco: 1,
        coordenadas: 1
      }
    }
  )
}

export const findDetail = id => {
  return collection().findOne(
    { _id: ObjectId(id) },
    {
      projection: {
        _id: 1,
        categoria: 1,
        created_at: 1,
        endereco: 1,
        coordenadas: 1,
        descricao: 1
      }
    }
  )
}

export const findImages = id => {
  return collection().findOne(
    { _id: ObjectId(id) },
    { projection: { _id: 1, imagens: 1 } }
  )
}

export const addImage = (id, dataUri) => {
  return collection().updateOne(
    { _id: ObjectId(id) },
    {
      $addToSet: {
        imagens: {
          id: ObjectId(),
          dataUri,
          status: 'novo',
          created_at: new Date()
        }
      }
    }
  )
}

export const removeImage = (id, imageId) => {
  return collection().updateOne(
    { _id: id },
    { $pull: { imagens: { id: imageId } } }
  )
}

export const findVideos = id => {
  return collection().findOne(
    { _id: ObjectId(id) },
    { projection: { _id: 1, videos: 1 } }
  )
}

export const addVideo = (id, dataUrl) => {
  return collection().updateOne(
    { _id: ObjectId(id) },
    {
      $addToSet: {
        videos: {
          id: ObjectId(),
          dataUrl,
          status: 'novo',
          created_at: new Date()
        }
      }
    }
  )
}

export const findNearby = (id, coordenadas) => {
  return collection()
    .find(
      {
        _id: { $ne: id },
        $or: [
          { imagens: { $gt: { $size: 0 } } },
          { videos: { $gt: { $size: 0 } } }
        ],
        coordenadas: {
          $near: [coordenadas.lat, coordenadas.lng]
        }
      },
      {
        projection: {
          categoria: 1,
          endereco: 1,
          created_at: 1
        },
        limit: 2
      }
    )
    .toArray()
}

export const list = () => {
  return collection()
    .find({}, { limit: 10, projection: { _id: 1 } })
    .toArray()
}

export const findRelated = id => {
  return collection()
    .find(
      {
        _id: { $ne: id },
        $or: [
          { imagens: { $gt: { $size: 0 } } },
          { videos: { $gt: { $size: 0 } } }
        ]
      },
      {
        projection: {
          categoria: 1,
          endereco: 1,
          created_at: 1
        },
        sort: { 'avg.up': -1 },
        limit: 2
      }
    )
    .toArray()
}
