import React from 'react'
import { Field, ErrorMessage } from 'formik'

export const AddressNumberField = () => {
  return (
    <div className="form-group">
      <label htmlFor="marcacaoEndNumero" className="col-sm-2 control-label">
        Número
      </label>
      <div className="col-sm-10">
        <Field
          className="form-control"
          id="marcacaoEndNumero"
          name="enderecoNumero"
          placeholder="Número"
        />
        <ErrorMessage name="enderecoNumero" className="help-block" />
      </div>
    </div>
  )
}
