import React from 'react'

export const AboutHome = () => (
  <div className="container">
    <form className="form-horizontal">
      <div className="panel panel-default">
        <div className="panel-heading">
          <h4>Sobre</h4>
        </div>
        <div className="panel-body">
          <p>
            Iniciado em 2011 como um projeto acadêmico o dedoduro.net criou
            corpo em 2012 como ferramenta de ouvidoria social. Seu objetivo é
            facilitar a comunicação entre cidadão e órgãos responsáveis pela
            zeladoria urbana.
          </p>
          <p>
            Seus fundadores - Guilherme Pacheco, Claudio Rodrigues e Rodrigo
            Soares, todos Analistas de Sistemas - acreditam que as redes sociais
            podem incentivar a resolução de problemas públicos, evitando
            burocracia e tempo, além disso, entendem a ferramenta como essencial
            para adequar os sistemas atuais de ouvidoria ao cenário tecnológico.
          </p>
          <p>
            O dedoduro.net é uma ferramenta para o cidadão, ela o ajuda a
            fiscalizar a cidade sem sair da sua rotina. Evitando os custos com
            fiscalização e agilizando o processo de abertura de ocorrência, o
            usuário pode adicionar uma foto ou um video junto a sua ocorrência.
          </p>
          <p>
            O dedoduro.net é uma ferramenta baseada na colaboração social,
            portanto a participação do cidadão é fundamental para o seu
            funcionamento.
          </p>
          <p>Obrigado pela sua colaboração!</p>
          <p>Juntos vamos conquistar uma cidade melhor.</p>
        </div>
      </div>
    </form>
  </div>
)
