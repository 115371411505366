import React from 'react'
import moment from 'moment'

const selectEnderecoTopo = event => {
  var endereco = event.endereco

  if (endereco.bairro) return endereco.bairro
  if (endereco.cidade) return endereco.cidade

  return ''
}

const selectCategoriaName = event => {
  if (event.categoria === 'alagamento') {
    return 'Alagamento'
  }

  if (event.categoria === 'buraco') {
    return 'Buraco'
  }

  if (event.categoria === 'iluminacao') {
    return 'Iluminação'
  }

  if (event.categoria === 'lixo') {
    return 'Lixo'
  }

  if (event.categoria === 'manutencao') {
    return 'Manutenção'
  }

  if (event.categoria === 'seguranca') {
    return 'Segurança'
  }

  if (event.categoria === 'transito') {
    return 'Trânsito'
  }

  if (event.categoria === 'vazamento') {
    return 'Vazamentos'
  }

  return 'Outros'
}

export const Header = ({ event }) => {
  return (
    <div
      className="intro staticMap"
      style={{
        backgroundImage: `url(https://api.mapbox.com/styles/v1/mapbox/outdoors-v11/static/${
          event.coordenadas.lng
        },${
          event.coordenadas.lat
        },14,0,0/640x100?access_token=pk.eyJ1IjoiZ3VpcGFjaGVjbzIiLCJhIjoiY2p0ZHcyYTlkMWJvbjQ5bnV6NjkwcG4zaCJ9.Yhb4czIk2CAZ_R2dv2cSkw`
      }}
    >
      <div className="container">
        <h2>
          <i>{event.endereco.nome}</i>
          <br />
          <i>{selectEnderecoTopo(event)}</i>
          <br />
        </h2>
        <p>
          <i className="fa fa-calendar">
            {moment(event.created_at).format('DD/MM/YYYY')}
          </i>
          <br />
          <i className="fa fa-tag">{selectCategoriaName(event)}</i>
        </p>
      </div>
    </div>
  )
}
