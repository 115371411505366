import React, { useState } from 'react'

export const Carousel = ({ imagens }) => {
  const [active, setActive] = useState(0)
  const last = imagens.length - 1

  return (
    <div className="carousel slide">
      <div className="carousel-inner">
        {imagens.map((image, i) => (
          <div
            key={image.id}
            className={`item ${i === active ? 'active' : ''}`}
          >
            <div className="text-center">
              <img alt="event" src={image.dataUri} />
            </div>
          </div>
        ))}
      </div>
      <button
        className="left carousel-control btn btn-link"
        onClick={() => setActive(active === 0 ? last : active - 1)}
      >
        <span className="icon-prev" />
      </button>
      <button
        className="right carousel-control btn btn-link"
        onClick={() => setActive(active < last ? active + 1 : 0)}
      >
        <span className="icon-next" />
      </button>
    </div>
  )
}
