import map from 'mapbox-gl'
import ReactMapboxGl from 'react-mapbox-gl'
import mbxClient from '@mapbox/mapbox-sdk'
import mbxGeocoding from '@mapbox/mapbox-sdk/services/geocoding'

const accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN
map.accessToken = accessToken

const baseClient = mbxClient({ accessToken })

export const geocodingClient = mbxGeocoding(baseClient)

export const Map = ReactMapboxGl({ accessToken })
