import React from 'react'
import { Router } from '@reach/router'

import { Header } from './components/Header'

import { About } from './features/About'
import { EventCreate } from './features/EventCreate'
import { EventCreateDetails } from './features/EventCreateDetails'
import { EventDetail } from './features/EventDetail'
import { EventEdit } from './features/EventEdit'
import { EventList } from './features/EventList'
import { EventListMap } from './features/EventListMap'
import { Home } from './features/Home'
import { LocationProvider } from './features/Location'
import { Login } from './features/Login'
import { MineEventList } from './features/MineEventList'
import { NotFound } from './features/NotFound'
import { SettingsSecurity } from './features/SettingsSecurity'

const App = () => (
  <LocationProvider>
    <Header />
    <Router>
      <Home path="/" />
      <EventCreate path="/new" />
      <EventCreateDetails path="/new/details" />
      <EventList path="/events" />
      <EventDetail path="/events/:eventId" />
      <EventDetail path="/events/:eventId/report" />
      <EventEdit path="/events/:eventId/edit/*" />
      <EventListMap path="/events/map" />
      <EventListMap path="/events/map/:eventId" />
      <MineEventList path="/mine" />
      <SettingsSecurity path="/settings" />
      <Login path="/login" />
      <About path="/about/*" />
      <NotFound default />
    </Router>
  </LocationProvider>
)

export default App
