import React from 'react'
import { Field, ErrorMessage } from 'formik'

export const DescriptionField = () => {
  return (
    <div className="form-group">
      <label htmlFor="marcacaoDescricao" className="col-sm-2 control-label">
        Descrição
      </label>
      <div className="col-sm-10">
        <Field
          component="textarea"
          id="marcacaoDescricao"
          className="form-control"
          name="descricao"
          rows="10"
          placeholder="Por favor, conte-nos alguns detalhes dessa dedurada."
          maxLength="5000"
          data-required="true"
          data-show-errors="false"
          data-rangelength="[5,5000]"
        />
        <ErrorMessage name="descricao" className="help-block" />
      </div>
    </div>
  )
}
