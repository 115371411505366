import React, { useEffect, useState } from 'react'
import { list } from '../../resources/eventResource'
import { Loading } from '../../components/Loading'
import { Event } from './components/Event'

export const EventList = () => {
  const [loading, setLoading] = useState(true)
  const [events, setEvents] = useState([])

  useEffect(() => {
    list().then(events => {
      setEvents(events)
      setLoading(false)
    })
  }, [])

  if (loading) {
    return <Loading />
  }

  return (
    <div id="listagem" className="listagem">
      <div className="row">
        {events.map(event => (
          <Event key={event._id} id={event._id} />
        ))}
      </div>
    </div>
  )
}
