import React, { Fragment } from 'react'
import { Link } from '@reach/router'

export const MineEventList = ({ cases = [] }) => (
  <Fragment>
    <div className="bs-header">
      <h1>
        <i className="fa fa-hand-o-up" />
        Minhas Deduradas
      </h1>
      <p>
        Juntos vamos conquistar lugares melhores de se viver, não deixe de
        participar.
      </p>
    </div>

    <div className="container">
      <div className="list-group">
        {cases.map(marcador => (
          <Link to={`/events/${marcador._id}`} className="list-group-item">
            <h4 className="list-group-item-heading">
              {'created_at'} {'categoria'}
            </h4>
            <p className="list-group-item-text">{'enderecoCompleto'}</p>
          </Link>
        ))}
      </div>
    </div>
  </Fragment>
)
