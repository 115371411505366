import React from 'react'

export const AboutTerms = () => (
  <div className="container">
    <form className="form-horizontal">
      <div className="panel panel-default">
        <div className="panel-heading">
          <h4>Termos</h4>
        </div>
        <div className="panel-body">
          <ol>
            <li>
              <ol>
                <li>
                  Esse Termo de Uso contém todas as informações necessárias
                  sobre a utilização do sistema DedoDuro, incluindo informações,
                  cores, imagens, logos, modo de uso, utilizadores e a
                  ferramenta que disponibiliza interação entre população e
                  empresas e/ou órgãos públicos.
                </li>
                <li>
                  Alterações nas ferramentas, layouts e estrutura visando
                  melhorar a utilização do sistema ou corrigir possíveis falhas
                  poderão ocorrer a qualquer momento sem aviso prévio. Qualquer
                  alteração realizada também estará sujeita aos termos aqui
                  apresentados.
                </li>
                <li>
                  A utilização do sistema competi em automática aceitação desses
                  termos.
                </li>
              </ol>
            </li>
            <li>
              Quanto aos usuários
              <ol>
                <li>
                  Os serviços estão disponíveis somente a pessoas maiores de 18
                  (dezoito) anos e que possuam capacidade civil, de acordo com o
                  estabelecido no Código Civil brasileiro.
                </li>
                <li>São USUÁRIOS do sistema DedoDuro@:</li>
                <li>
                  <ol>
                    <li>
                      Pessoas físicas, na condição de cidadão, nacionais e
                      estrangeiras, submetendo-se estas últimas, desde já, à
                      legislação brasileira vigente.
                    </li>
                    <li>
                      Pessoas jurídicas, sejam empresas públicas ou privadas,
                      cadastradas no território nacional brasileiro de acordo
                      com a legislação vigente.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              Utilização do Site
              <ol>
                <li>
                  O DedoDuro é um sistema que permite a interação da população
                  com órgãos públicos e/ou empresas responsáveis pela manutenção
                  e cuidado do patrimônio público, desde uma lâmpada queimada
                  até um local de alto índice de criminalidade. Seu uso é
                  exclusivo para esse fim.
                </li>
                <li>
                  A utilização do sistema DedoDuro é gratuita para todo Usuário
                  Pessoa Física interessado em contribuir com a melhoria do
                  patrimônio público.
                </li>
                <li>
                  Toda informação fornecida pelo usuário será de única e
                  exclusiva utilização pelo próprio sistema, podendo ser
                  consultada por qualquer outro usuário ou empresa através do
                  mapa e gráficos disponíveis.
                </li>
                <li>
                  Empresas responsáveis pela manutenção poderão interagir com
                  usuários dando a eles um respaldo sobre a correção/tratamento
                  do problema apresentado.
                </li>
                <li>
                  Usuários poderão analisar e votar se as medias tomadas pelos
                  órgãos públicos ou empresas foram eficazes.
                </li>
                <li>
                  As informações contidas no sistema DedoDuro poderão ser
                  utilizadas de forma estatística por usuários ou empresas
                  especializadas para fornecer a população melhores opções de
                  rotas, compras, locomoções ou segurança.
                </li>
              </ol>
            </li>
            <li>
              Política de segurança
              <ol>
                <li>
                  Todas as informações pessoais de usuários, como nome, cpf,
                  endereço, idade, e-mail e qualquer outro dado fornecido serão
                  de uso exclusivo do sistema DedoDuro. Não comercializamos nem
                  repassamos informações de usuários.
                </li>
                <li>
                  Todo usuário cadastrado poderá ter acesso e ser informado por
                  e-mail a dados específicos de regiões de interesse, que
                  poderão ser configuradas no sistema DedoDuro.
                </li>
                <li>
                  Todo órgão público ou empresa cadastrada terá acesso direto a
                  ocorrências publicadas com todos os dados informados mas não
                  terão acesso aos dados do reclamante.
                </li>
                <li>
                  O Sistema DedoDuro utiliza níveis de segurança dentro dos
                  requisitos legais para proteger os dados pessoais. Entretanto,
                  é importante que o usuário tenha ciência de que pela própria
                  natureza e características técnicas da internet, essa proteção
                  não é infalível e encontra-se sujeita à violação pelas mais
                  diversas práticas maliciosas.
                </li>
              </ol>
            </li>
          </ol>
        </div>
      </div>
    </form>
  </div>
)
