import React, { Fragment } from 'react'

export const SettingsSecurity = () => {
  const seguranca = {
    services: {
      facebook: {
        id: '',
        name: '',
        email: ''
      },
      google: {
        id: '',
        name: '',
        email: ''
      }
    },
    profile: {
      name: ''
    }
  }

  return (
    <div className="container">
      <div className="panel panel-default">
        <div className="panel-heading">
          <h4>Segurança</h4>
        </div>
        <div className="panel-body">
          <div className="row">
            <div className="col-sm-4">
              <div className="thumbnail">
                <div className="text-center">
                  <i className="fa fa-facebook-square fa-5x" />
                  <h3>Facebook</h3>
                </div>
                <div className="caption">
                  {seguranca ? (
                    <dl>
                      <dt>Nome</dt>
                      <dd>{seguranca.services.facebook.name}</dd>
                      <dt>Email</dt>
                      <dd>{seguranca.services.facebook.email}</dd>
                      <dt>Profile</dt>
                      <dd>
                        <a
                          href="{{seguranca.services.facebook.link}}"
                          target="_blank"
                        >
                          {seguranca.services.facebook.id}
                        </a>
                      </dd>
                    </dl>
                  ) : (
                    <Fragment>
                      <p>
                        Você pode vincular seu login com sua conta do Facebook.
                      </p>
                      <p className="text-center">
                        <button
                          className="btn btn-primary btn-sm"
                          id="addLoginWithFacebook"
                        >
                          Vincular com Facebook
                        </button>
                      </p>
                    </Fragment>
                  )}
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="thumbnail">
                <div className="text-center">
                  <i className="fa fa-google-plus-square fa-5x" />
                  <h3>Google</h3>
                </div>
                <div className="caption">
                  {seguranca.services.google ? (
                    <dl>
                      <dt>Nome</dt>
                      <dd>{seguranca.services.google.name}</dd>
                      <dt>Email</dt>
                      <dd>{seguranca.services.google.email}</dd>
                      <dt>Profile</dt>
                      <dd>
                        <a
                          href="https://plus.google.com/{seguranca.services.google.id}"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {seguranca.services.google.id}
                        </a>
                      </dd>
                    </dl>
                  ) : (
                    <Fragment>
                      <p>
                        Você pode vincular seu login com sua conta do Google.
                      </p>
                      <p className="text-center">
                        <button
                          className="btn btn-primary btn-sm"
                          id="addLoginWithGoogle"
                        >
                          Vincular com Google
                        </button>
                      </p>
                    </Fragment>
                  )}
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="thumbnail">
                <div className="text-center">
                  <i className="fa fa-lock fa-5x" />
                  <h3>Senha</h3>
                </div>
                <div className="caption">
                  <dl>
                    <dt>Nome</dt>
                    <dd>{seguranca.profile.name}</dd>
                    <dt>Email</dt>
                    <dd>{seguranca.profile.email}</dd>
                  </dl>
                  {seguranca.services.password ? (
                    seguranca.services.password.reset ? (
                      <p>
                        As instruções para definição de sua senha foram enviadas
                        para {seguranca.services.password.reset.email}.
                      </p>
                    ) : (
                      <Fragment>
                        <p>Você possui uma senha definida.</p>
                        <p className="text-center">
                          <button
                            className="btn btn-primary btn-sm"
                            id="addLoginWithPassword"
                          >
                            Não me lembro dela
                          </button>
                        </p>
                      </Fragment>
                    )
                  ) : (
                    <Fragment>
                      <p>Você ainda não definiu uma senha para login.</p>
                      <p className="text-center">
                        <button
                          className="btn btn-primary btn-sm"
                          id="addLoginWithPassword"
                        >
                          Criar Senha
                        </button>
                      </p>
                    </Fragment>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
