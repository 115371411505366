import React, { useContext } from 'react'
import { Link } from '@reach/router'
import { Location } from '../Location'

export const EventCreate = () => {
  const location = useContext(Location)

  const handleCurrentPositionButtonClick = evt => {
    evt.preventDefault()
    location.requestCurrentPosition('')
  }

  const handleSearchFormSubmit = evt => {
    evt.preventDefault()
    location.requestCurrentPosition(evt.target.elements.address.value)
  }

  return (
    <div className="container">
      <div className="bs-header">
        <h1>Onde Dedurar</h1>
        <p>Aponte o local de sua Dedurada</p>
      </div>

      <div className="container">
        <div className="localizar">
          <button
            className="btn btn-info btn-block btn-sm buscarGPS"
            onClick={handleCurrentPositionButtonClick}
            disabled={location.busy}
          >
            <i className="fa fa-map-marker" /> Bem aqui onde estou!
          </button>

          <h5 className="text-center">ou</h5>

          <form onSubmit={handleSearchFormSubmit} className="mapa-busca">
            <div className="row">
              <div className="col-lg-12">
                <div className="input-group">
                  <input
                    type="text"
                    id="address"
                    className="form-control"
                    autoComplete="off"
                    placeholder="Ex: Av Paulista 650 São Paulo"
                    autoFocus
                  />
                  <span className="input-group-btn">
                    <button
                      className="btn btn-default"
                      disabled={location.busy}
                    >
                      <i className="fa fa-search" />
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </form>

          <div className="buscaResultados">
            <div className="list-group">
              {location.results &&
                location.results.map(result => (
                  <Link
                    to="details"
                    className="list-group-item"
                    key={result.id}
                    state={{
                      name: result.text_pt,
                      coords: {
                        longitude: result.geometry.coordinates[0],
                        latitude: result.geometry.coordinates[1]
                      },
                      context: result.context.reduce((sum, context) => {
                        sum[context.id.split('.')[0]] = context.text_pt

                        return sum
                      }, {})
                    }}
                  >
                    <strong>{result.text_pt}</strong>
                    <br />
                    {result.place_name_pt}
                  </Link>
                ))}

              {location.results && location.results.length === 0 && (
                <div className="alert alert-danger">
                  <strong>Arrgg :S</strong>
                  <br />
                  <p>
                    Não conseguimos localizar exatamente onde quer dedurar.
                    <br />
                    Tente refazer sua busca com mais detalhes.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
