import React, { Fragment } from 'react'

export const Login = () => {
  let showName
  let showSenha
  let action

  return (
    <Fragment>
      <div className="bs-header">
        <h1>Faça o Login</h1>
        <p>Você precisa estar logado para Dedurar</p>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-offset-3 col-md-6">
            <div className="row">
              <div className="col-xs-6">
                <button
                  id="loginWithFacebook"
                  className="btn  btn-block btn-primary"
                  type="button"
                >
                  <i className="fa fa-facebook-square fa-3x" />
                  <h4>Login</h4>
                </button>
              </div>
              <div className="col-xs-6">
                <button
                  id="loginWithGoogle"
                  className="btn  btn-block btn-danger"
                  type="button"
                >
                  <i className="fa fa-google-plus-square fa-3x" />
                  <h4>Login</h4>
                </button>
              </div>
            </div>
            <div className="row">
              <h3 className="text-center">
                <small>OU</small>
              </h3>
            </div>
            <div className="row">
              <div className="col-xs-12">
                <h3>{'loginTitle'}</h3>
                <div id="loginMessages" />
                <form id="loginWithPassword" action="login">
                  {showName && (
                    <div id="inputNome" className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Nome"
                      />
                    </div>
                  )}
                  <div id="inputEmail" className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Email"
                    />
                  </div>
                  {showSenha && (
                    <div id="inputSenha" className="form-group">
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Senha"
                      />
                    </div>
                  )}
                  <button
                    className="btn btn-sm btn-info btn-block"
                    type="submit"
                  >
                    {'loginText'}
                  </button>
                </form>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 text-center">
                <hr />
                {action === 'login' && (
                  <button id="goFormLogin" className="btn btn-block btn-link">
                    Voltar ao Login.
                  </button>
                )}
                {action === 'cadastro' && (
                  <button
                    id="goFormRegistrar"
                    className="btn btn-block btn-link"
                  >
                    Ainda não tem uma conta? Cadastre-se
                  </button>
                )}
                {action === 'recuperar' && (
                  <button
                    id="goFormRecuperar"
                    className="btn btn-block btn-link"
                  >
                    Esqueceu sua senha?
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}
