import React from 'react'
import { Link, Router } from '@reach/router'
import { AboutHome } from '../AboutHome'
import { AboutTerms } from '../AboutTerms'

export const About = () => (
  <React.Fragment>
    <div className="bs-header">
      <h1>
        <i className="fa fa-info-circle" />
        Sobre
      </h1>
      <p>Saiba quem e como somos.</p>
    </div>

    <div className="container">
      <ul className="nav nav-pills nav-justified nav-inline">
        <li>
          <Link to="/about">
            <span className="fa-stack fa-lg">
              <i className="fa fa-circle fa-stack-2x" />
              <i className="fa fa-file-text-o fa-stack-1x fa-inverse" />
            </span>
            <h4 className="hidden-xs">Sobre</h4>
          </Link>
        </li>
        <li>
          <Link to="terms">
            <span className="fa-stack fa-lg">
              <i className="fa fa-circle fa-stack-2x" />
              <i className="fa fa-file-text-o fa-stack-1x fa-inverse" />
            </span>
            <h4 className="hidden-xs">Termos</h4>
          </Link>
        </li>
      </ul>
    </div>

    <br />

    <Router>
      <AboutHome path="/" />
      <AboutTerms path="/terms" />
    </Router>
  </React.Fragment>
)
