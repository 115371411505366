import React from 'react'

export const Loading = () => {
  return (
    <h1 className="loading text-center">
      <i className="fa fa-spinner fa-spin fa-lg" />
      Carregando!
    </h1>
  )
}
