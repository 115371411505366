import React from 'react'
import { Map } from '../Map'

export const EventListMap = () => {
  return (
    <Map
      // eslint-disable-next-line react/style-prop-object
      style="mapbox://styles/mapbox/streets-v9"
      containerStyle={{
        height: '100vh',
        width: '100vw'
      }}
    />
  )
}
