import { object, number, string } from 'yup'

export const validationSchema = object().shape({
  enderecoNumero: number()
    .typeError('Deve conter apenas números')
    .positive('Deve ser um número posítivo')
    .integer('Deve ser um número inteiro'),

  categoria: string().required(
    'Selecione categoria que melhor simboliza sua dedurada'
  ),

  descricao: string()
    .min(5, 'Deve conter de 5 à 5000 caracteres')
    .max(5000, 'Deve conter de 5 à 5000 caracteres')
    .required('Obrigatório')
})
